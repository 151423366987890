@tailwind base;
@tailwind components;
@tailwind utilities;


/* Flip card container */
.flip-card {
    perspective: 1000px; /* Gives the container a 3D space */
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 300px; /* Set a fixed height for the card */
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d; /* Keeps the 3D effect */
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg); /* Flips the card when hovered */
  }
  
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%; /* Ensure the height matches the card container */
    backface-visibility: hidden; /* Ensures the hidden side stays invisible */
    border-radius: 12px; /* Matches the card's rounded corners */
  }
  
  /* Front side styles */
  .flip-card-front {
    background-color: blue;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  /* Back side styles */
  .flip-card-back {
    background-color: white;
    color: blue;
    transform: rotateY(180deg); /* Initially hides the back side */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  